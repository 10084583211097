import axios from "axios";
import authHeader from "@/services/api/auth-header";
import METHODS from "@/services/api/apiMethods";


let BASE_URL;

if (process.env.NODE_ENV === "development") {
    BASE_URL = process.env.VUE_APP_DEV_TALENT_BASE_URL;
} else {
    BASE_URL = process.env.VUE_APP_PROD_TALENT_BASE_URL;
}

const callEndpoint = async ({method, url, data, needsAuth = true}, SERVICE_BASE_URL = BASE_URL) => {
    const { GET, DELETE } = METHODS
    url = SERVICE_BASE_URL + url
    if (!method) method = GET
    if (method === GET || method === DELETE) {
        if (needsAuth) {
            return axios[method](url, { headers: authHeader() })
        } else return axios[method](url)
    } else {
        if (needsAuth) {
            return axios[method](url, data, { headers: authHeader() })
        } else return axios[method](url, data)
    }
}
export const attachQueryParamsToUrl = (url, query) => {
    console.log(query)
    url += '?'
    const keys = Object.keys(query)
    keys.forEach((key, index) => {
        url += `${key}=${query[key]}${index === keys.length - 1 ? '' : '&'}`
    })
    // const lastIndexOfAmpersand = url.lastIndexOf('&')
    // url[lastIndexOfAmpersand] = ''
    return url
}

export default callEndpoint
