import actions from './actions'
import mutations from './mutations'
import getters from './getters'


const state = {
    reviews:{
        results:[]
    },
    ratingInsight:{},
    companyProfile:{
        "id": 1,
        "followers": 0,
        "is_follower": false,
        "jobs": 1,
        "reviews": 0,
        "contact_person": "test_contact1@user.com",
        "company_logo": "",
        "website": "",
        "company_name": "test_company_1",
        "company_size": "",
        "company_overview": "",
        "industry": "",
        "site_name": "12345"
    },
    loading: false,
    faqs:[],
    descriptionAndMedia:[],
    companyAwards:[],
    jobs:[]
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}