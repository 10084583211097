<template>
  <div class="tw-flex">
    <svg @click="$emit('click')" class="tw-mr-2" width="37" height="43" viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <circle cx="19.3806" cy="15.8819" r="10.5384" stroke="#008EEF" stroke-width="7.98716"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7918 33.0854C32.6021 31.665 30.4967 31.5084 28.8902 32.4315C25.769 34.2248 22.1823 35.1107 18.5407 34.9501C14.899 34.7896 11.4042 33.5915 8.45298 31.5304C6.93395 30.4696 4.82295 30.4403 3.51284 31.7504C2.20274 33.0605 2.19135 35.205 3.66028 36.3342C7.81554 39.5283 12.8692 41.3976 18.1557 41.649C18.1855 41.6504 18.2154 41.6518 18.2452 41.6531C18.275 41.6544 18.3048 41.6557 18.3346 41.6569C23.6228 41.8717 28.8213 40.4544 33.2416 37.6384C34.8042 36.6429 34.9816 34.5057 33.7918 33.0854Z" fill="#008EEF"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="35.4003" height="41.3003" fill="white" transform="translate(1.15332 0.965332)"/>
        </clipPath>
      </defs>
    </svg>
    <svg width="48" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.61" y="0.61" width="46.78" height="18.78" rx="8.27889" fill="white" stroke="#AAB7DB" stroke-width="1.22"/>
      <path d="M11.5661 14.4443V6.14798H14.8964C15.7577 6.14798 16.4135 6.34946 16.8638 6.75242C17.3221 7.14748 17.5512 7.65711 17.5512 8.28131C17.5512 8.80279 17.409 9.22156 17.1246 9.53761C16.848 9.84576 16.5083 10.0551 16.1053 10.1658C16.5794 10.2606 16.9705 10.4976 17.2786 10.8769C17.5868 11.2482 17.7409 11.6828 17.7409 12.1806C17.7409 12.8364 17.5038 13.3776 17.0298 13.8043C16.5557 14.2309 15.8841 14.4443 15.0149 14.4443H11.5661ZM13.0831 9.63242H14.6712C15.0979 9.63242 15.4258 9.53366 15.6549 9.33613C15.8841 9.1386 15.9986 8.8581 15.9986 8.49465C15.9986 8.14699 15.8841 7.8744 15.6549 7.67687C15.4337 7.47144 15.0979 7.36872 14.6475 7.36872H13.0831V9.63242ZM13.0831 13.2117H14.7779C15.2283 13.2117 15.5759 13.109 15.8209 12.9035C16.0737 12.6902 16.2001 12.3939 16.2001 12.0146C16.2001 11.6275 16.0698 11.3233 15.809 11.1021C15.5483 10.8808 15.1967 10.7702 14.7542 10.7702H13.0831V13.2117ZM19.0429 14.4443V6.14798H24.4592V7.36872H20.5599V9.64427H24.1036V10.8295H20.5599V13.2235H24.4592V14.4443H19.0429ZM27.8266 14.4443V7.36872H25.4088V6.14798H31.7733V7.36872H29.3436V14.4443H27.8266ZM31.4841 14.4443L34.5182 6.14798H36.2249L39.2589 14.4443H37.6471L36.9834 12.5243H33.7478L33.0723 14.4443H31.4841ZM34.1626 11.3391H36.5686L35.3597 7.8902L34.1626 11.3391Z" fill="#142E70"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "EnumWithoutWordIcon"
}
</script>

<style scoped>

</style>