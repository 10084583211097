<template>
  <div class="tw-p-5">
    <div class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-pt-20" style="margin: 0 auto">
      <v-card flat  >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="tw-my-5">
          <h4 class="find">Find jobs that interest you</h4>
        </div>
        <div class="md:tw-flex ">
          <div class="md:tw-mr-4 tw-mb-4 md:tw-mb-0">
            <small>First Name</small>
            <validation-provider
                v-slot="{ errors }"
                name="First Name"
                rules="required">
            <v-text-field
                id="First Name"
                hide-details
                label="Enter your first name"
                v-model="user.first_name"
                solo/>
              <span class="err" >{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div>
            <small>Last Name</small>
            <validation-provider
                v-slot="{ errors }"
                name="Last Name"
                rules="required">
            <v-text-field
                id="Last Name"
                hide-details
                label="Enter your last name"
                v-model="user.last_name"
                solo/>
              <span class="err">{{ errors[0] }}</span>
            </validation-provider>
          </div>

        </div>
        <br>
        <div>
          <small>Email Address</small>
          <validation-provider
              v-slot="{ errors }"
              name="Email"
              rules="required|email">
          <v-text-field
              id="email"
              hide-details
              label="Enter your email address"
              @input="value => { user.email = value.toLowerCase();}"
              v-model="user.email"
              solo/>
            <span class="err">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <br>
        <div>
          <small>Create Password</small>
          <validation-provider
              v-slot="{ errors }"
              name="Password"
              rules="required|min:8">
          <v-text-field
              id="password"
              hide-details
              label="••••••••••••••"
              v-model="user.password"
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' : 'password'"
              solo/>
            <span class="err">{{ errors[0] ? 'password must be at least 8 characters' : '' }}</span>
          </validation-provider>
        </div>
        <div class="tw-flex tw-items-center tw-mt-3">
          <v-checkbox color="purple" v-model="checkbox"/>
        <span @click="checkbox = !checkbox" class="agree tw-cursor-pointer">
          I agree to
          <span class="terms">
            terms & conditions
          </span>
          and
          <span class="terms">
            privacy policy
          </span>
        </span>
        </div>
        <div class="tw-mt-2">
          <BaseButton :loading="loading" :block="true" type="submit" button-text="Sign up"/>
        </div>
        <div class="tw-flex tw-items-center">
          <v-divider/>
          <span class="or tw-m-4">Or</span>
          <v-divider/>
        </div>
      </form>

      </validation-observer>
        <div>
          <BaseButton :block="true" :outlined="true" @click="loginWithGoogle" type="button" button-text="Sign In with Google">
            <template #left-icon>
              <GoogleIcon/>
            </template>
          </BaseButton>
          <!--        <BaseButton class="tw-my-4" :block="true" type="button"  :outlined="true" button-text="Sign In with LinkedIn">-->
          <!--          <template #left-icon>-->
          <!--            <LinkedInIcon/>-->
          <!--          </template>-->
          <!--        </BaseButton>-->
        </div>

      </v-card>

    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import GoogleIcon from "@/components/icons/GoogleIcon";
// import LinkedInIcon from "@/components/icons/LinkedInIcon";
import {signUp} from "@/services/api/AuthApiService";
import oauth from "@/mixins/oauth.mixin"


export default {
  name: "SignUp",
  mixins: [oauth],
  components: { GoogleIcon, BaseButton},
  data(){
    return{
      value: false,
      checkbox:false,
      loading:false,
      number: '',
      user:{
        first_name:'',
        last_name:'',
        email:'',
        password: '',
      }
    }
  },
  methods:{
    submit(){
      if (this.checkTerm()){
        sessionStorage.removeItem('userToken')
        this.loading = true

        signUp(this.user)
        .then(res=>{
          console.log(res.data)
          this.$router.push({name:'SuccessNotification'})
        })
        .catch(err=>{
          let errorKey = Object.keys(err.response.data)
          this.$displaySnackbar({
            message: err.response.data[errorKey[0]][0],
            success: false
          })
        })
        .finally(()=>this.loading = false)
      }
    },
    async loginWithGoogle(){
      await this.googleAuth()
    },
    checkTerm() {
      if (this.checkbox) {
        return true
      } else {
        this.$displaySnackbar({
          message: 'Please check the T&C',
          success: false
        })
        return false
      }
    },

  }
}
</script>

<style scoped>
.find {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}

.agree {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #696F79;
  margin-left: 3px;
}

.terms {
  color: #008EEF;
  cursor: pointer;
}
.or{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #475661;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;

}
</style>
