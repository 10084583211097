export default {
    SET_APPLICANT_DATA:(state,data) =>{
        state.profileData = data
    },
    SET_APPLICANT_CV:(state,data) =>{
        state.applicantCV = data
    },
    SET_PROFILE:(state,data)=>{
        state.user = data.data
        let user = JSON.parse(sessionStorage.getItem('userData'))
        state.user.personal_information.name = user.name
        state.user.personal_information.age = (new Date().getFullYear() - data.data.personal_information.date_of_birth.substr(0, 4))
    },
    SET_RESUME:(state, data)=> {
        state.resume_info = data
    }
}
