import {
    getAllEducations,
    addEducation,
    updateEducation,
    deleteEducation,
    adminGetAllSpecialProfiles, adminGetAllEducations
} from '@/services/api/APIService'
import displaySnackbar from "@/services/utils/snackbar";
import {
    addAccomplishmentToSpecialProfile,
    addCertificateToSpecialProfile,
    addLink,
    addWorkExperienceToSpecialProfile,
    createSpecializeProfile,
    deleteAccomplishmentToSpecialProfile,
    deleteCertificateToSpecialProfile, deleteJobAlert,
    deleteWorkExperienceToSpecialProfile,
    getAllLinks,
    getAllSpecialProfiles, getUsersJobAlerts,
    updateAccomplishmentToSpecialProfile,
    updateCertificateToSpecialProfile,
    updateLink, updateSkillsToSpecialProfile, updateSpecializeProfile,
    updateWorkExperienceToSpecialProfile
} from "../../services/api/APIService";

export default {
    async getProfileData({commit},data){
        await createSpecializeProfile(data).then(res =>{
            commit('GET_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async getAllEducations({commit}){
        commit('SET_LOADING',true)
      await getAllEducations().then(res =>{
          commit('GET_EDUCATIONS',res.data)
          commit('SET_LOADING',false)
      })
          .catch(err=>{
              let errorKey = Object.keys(err.response.data)
              commit('SET_LOADING',false)
              displaySnackbar({
                  message: err.response.data[errorKey[0]],
                  success: false
              })
          })
    },
    async adminGetAllEducations({commit}, email){
        commit('SET_LOADING',true)
        await adminGetAllEducations(email).then(res =>{
            commit('GET_EDUCATIONS',res.data)
            commit('SET_LOADING',false)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                commit('SET_LOADING',false)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async addEducation({commit},data){
        commit('SET_LOADING',true)
        await addEducation(data).then(res =>{
            commit('ADD_EDUCATION',res.data)
            commit('SET_LOADING',false)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                commit('SET_LOADING',false)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async updateEducation({commit},data){
        commit('SET_LOADING',true)
        await updateEducation(data).then(res =>{
            commit('UPDATE_EDUCATION',res.data)
            commit('SET_LOADING',false)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                commit('SET_LOADING',false)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async deleteEducation({commit},educationId){
        commit('SET_LOADING',true)
        await deleteEducation(educationId).then(()=>{
            commit('DELETE_EDUCATION',educationId)
            commit('SET_LOADING',false)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                commit('SET_LOADING',false)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async addLink({commit}, data){
        await addLink(data).then(() =>{
            commit('ADD_LINK',data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async updateLink({commit}, data){
        await updateLink(data).then(() =>{
            commit('UPDATE_LINK',data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async getAllLinks({commit}){
        await getAllLinks().then(res =>{
            commit('ALL_LINKS',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async updateSpecializeProfile({commit},data){
        await updateSpecializeProfile(data).then(res =>{
            res.data.special_profile_id = data.special_profile_id
            commit('UPDATE_SPECIALIZE_PROFILE',res.data)
        })
    },
   async getAllSpecialProfiles({commit}){
        await getAllSpecialProfiles().then(res =>{
            commit('ALL_SPECIAL_PROFILES', res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async adminGetAllSpecialProfiles({commit}, email){
        await adminGetAllSpecialProfiles(email).then(res =>{
            commit('ALL_SPECIAL_PROFILES', res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async addWorkExperience({commit},data){
        await addWorkExperienceToSpecialProfile(data).then(res =>{
            res.data.special_profile_id= data.special_profile_id
            commit('ADD_WORK_EXPERIENCE_TO_SPECIAL_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async updateWorkExperience({commit},data){
        await updateWorkExperienceToSpecialProfile(data).then(res =>{
            res.data.special_profile_id= data.special_profile_id
            console.log(res.data)
            commit('UPDATE_WORK_EXPERIENCE_TO_SPECIAL_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async deleteWorkExperience({commit},data){
        await deleteWorkExperienceToSpecialProfile(data).then(()=>{
            commit('DELETE_WORK_EXPERIENCE_TO_SPECIAL_PROFILE', data)
        })
            .catch( err=>{
                displaySnackbar({
                    message: err.response.data,
                    success: false
                })
            })
    },
   async addCertificateToSpecialProfile({commit},data){
        await  addCertificateToSpecialProfile(data).then(res=>{
            res.data.special_profile_id= data.special_profile_id
            commit('ADD_CERTIFICATE_TO_SPECIAL_PROFILE', res.data)
        })
            .catch( err=>{
                displaySnackbar({
                    message: err.response.data,
                    success: false
                })
            })
    },

    async updateCertificateToSpecialProfile({commit},data){
        await updateCertificateToSpecialProfile(data).then(res =>{
            res.data.special_profile_id= data.special_profile_id
            commit('UPDATE_CERTIFICATE_TO_SPECIAL_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async deleteCertificateToSpecialProfile({commit},data){
        await deleteCertificateToSpecialProfile(data).then(()=>{
            commit('DELETE_CERTIFICATE_TO_SPECIAL_PROFILE', data)
        })
            .catch( err=>{
                displaySnackbar({
                    message: err.response.data,
                    success: false
                })
            })
    },

    async addAccomplishmentToSpecialProfile({commit},data){
        await  addAccomplishmentToSpecialProfile(data).then(res=>{
            res.data.special_profile_id= data.special_profile_id
            commit('ADD_ACCOMPLISHMENT_TO_SPECIAL_PROFILE', res.data)
        })
            .catch( err=>{
                displaySnackbar({
                    message: err.response.data,
                    success: false
                })
            })
    },

    async updateAccomplishmentToSpecialProfile({commit},data){
        await updateAccomplishmentToSpecialProfile(data).then(res =>{
            res.data.special_profile_id= data.special_profile_id
            commit('UPDATE_ACCOMPLISHMENT_TO_SPECIAL_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async deleteAccomplishmentToSpecialProfile({commit},data){
        await deleteAccomplishmentToSpecialProfile(data).then(()=>{
            commit('DELETE_ACCOMPLISHMENT_TO_SPECIAL_PROFILE', data)
        })
            .catch( err=>{
                displaySnackbar({
                    message: err.response.data,
                    success: false
                })
            })
    },

    async updateSkillsToSpecialProfile({commit},data){
        await updateSkillsToSpecialProfile(data).then(res =>{
            res.data.special_profile_id= data.special_profile_id
            commit('UPDATE_SKILLS_TO_SPECIAL_PROFILE',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },

    async getUserJobAlerts ({commit}) {
        await getUsersJobAlerts().then(res =>{
            commit('USER_JOB_ALERTS',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    },
    async deleteJobAlert({commit},data){
        await  deleteJobAlert(data).then(res =>{
            commit('USER_JOB_ALERTS',res.data)
        })
            .catch(err=>{
                let errorKey = Object.keys(err.response.data)
                displaySnackbar({
                    message: err.response.data[errorKey[0]],
                    success: false
                })
            })
    }
}