export default {

    GET_EDUCATIONS(state,data){
        state.educations = data
    },
    ADD_EDUCATION(state,data){
        state.educations.push(data)
    },

    UPDATE_EDUCATION(state,data){
        state.educations = state.educations.filter(edu => edu.id !== data.id);
        state.educations.push(data)
    },

    DELETE_EDUCATION(state,educationId){
        state.educations = state.educations.filter(edu => edu.id !== educationId)
    },
    SET_LOADING(state,status){
        state.educationLoading = status
    },

    ADD_LINK(state,data){
        state.links.push(data)
    },
    UPDATE_LINK(state,data){
        let linkInd = state.links.findIndex(link => link.id === data.id);
        state.links[linkInd] = data
    },

    ALL_LINKS(state,data){
        state.links = data
    },

    ALL_SPECIAL_PROFILES(state,data){
        state.specialProfiles = data
    },
    UPDATE_SPECIALIZE_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.job_role = data.job_role
        profile.description = data.description
        profile.is_default = data.is_default
    },
    ADD_WORK_EXPERIENCE_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_work_experience.push(data)
    },
    UPDATE_WORK_EXPERIENCE_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
      profile.specialized_work_experience.map((exp) =>{
          if (exp.id === data.id){
              exp = data
          }
      })
    },
    DELETE_WORK_EXPERIENCE_TO_SPECIAL_PROFILE(state,data){
        let index = state.specialProfiles.findIndex(obj => obj.id === data.special_profile_id)
        state.specialProfiles[index].specialized_work_experience =
            state.specialProfiles[index].specialized_work_experience.filter((obj) => obj.id !== data.id)

    },
    ADD_CERTIFICATE_TO_SPECIAL_PROFILE(state, data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_certification.push(data)
    },
    UPDATE_CERTIFICATE_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_certification.map((cert) =>{
            if (cert.id === data.id){
                cert = data
            }
        })
    },

    DELETE_CERTIFICATE_TO_SPECIAL_PROFILE(state,data){
        let index = state.specialProfiles.findIndex(obj => obj.id === data.special_profile_id)
        state.specialProfiles[index].specialized_certification =
            state.specialProfiles[index].specialized_certification.filter((obj) => obj.id !== data.id)
    },

    ADD_ACCOMPLISHMENT_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_accomplishment.push(data)
    },

    UPDATE_ACCOMPLISHMENT_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_accomplishment.map((acc) =>{
            if (acc.id === data.id){
                acc = data
            }
        })
    },

    DELETE_ACCOMPLISHMENT_TO_SPECIAL_PROFILE(state,data){
        let index = state.specialProfiles.findIndex(obj => obj.id === data.special_profile_id)
        state.specialProfiles[index].specialized_accomplishment =
            state.specialProfiles[index].specialized_accomplishment.filter((obj) => obj.id !== data.id)
    },

    UPDATE_SKILLS_TO_SPECIAL_PROFILE(state,data){
        let profile = state.specialProfiles.find(profile => profile.id === data.special_profile_id)
        profile.specialized_skill = [data]
    },

    USER_JOB_ALERTS(state,data){
        let alerts = {}
        for (const [key,value] of Object.entries(data)){
            alerts[key] = value}
        state.jobAlerts = alerts
    },


}