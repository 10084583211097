import {
    getReviews,
    getCompanyFaqs,
    getCompanyDescriptionAndMedia,
    getCompanyAwards,
    saveAJob,
    getAllCompanyJobsAsATalent, getReviewsPaginated, getReviewInsight
} from "@/services/api/APIService";
import {getCompanySummary} from "@/services/api/AuthApiService";
import {viewFile} from "@/services/utils/aws";



export default {
    async fetchReview({commit}, payload) {
        await getReviews(payload).then(res => {
            console.log(res)
            commit('SET_REVIEW', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    async fetchRatingInsight({commit}, payload) {
        await getReviewInsight(payload).then(res => {
            console.log(res)
            commit('SET_REVIEW_INSIGHT', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    async fetchPaginatedReview({commit}, payload) {
        await getReviewsPaginated(payload).then(res => {
            console.log(res)
            commit('SET_REVIEW', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    async fetchCompanyProfileSummary({commit}, payload) {
        // commit('SET_LOADING', true)
        await getCompanySummary({site_name: payload.site_name, candidate: payload.email}).then(async res => {
            sessionStorage.setItem("companyId", JSON.stringify(res.data.id))
            for (const property of ['cover_image', 'company_logo']) {
                await viewFile({ url: res.data[property], context: res.data, property })
            }
            commit('SET_COMPANY_SUMMARY', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            // .finally(() => commit('SET_LOADING', false))
    },
    async getCompanyFaqs({commit}, site_name) {
        return getCompanyFaqs(site_name).then(
            res => {
                commit('getCompanyFaqsSuccess', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },
    async getDescriptionAndMedia({commit, dispatch}, site_name) {
        commit('SET_LOADING', true)
        let user = JSON.parse(sessionStorage.getItem('userData'))
        try {
            const {data: description} = await getCompanyDescriptionAndMedia(site_name)
            commit('getDescriptionAndMediaSuccess', description)
            await dispatch('getCompanyFaqs', site_name)
            await dispatch('fetchReview', description.id)
            await dispatch('fetchRatingInsight', description.id)
            await dispatch('getCompanyAwards', site_name)
            await dispatch('getAllCompanyJobsAsATalent', {site_name: site_name , email: user.email})
        } catch (e) {
            console.log(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async getCompanyAwards({commit}, site_name) {
        return getCompanyAwards(site_name).then(
            res => {
                console.log(res.data)
                commit('getCompanyAwardsSuccess', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )

    },
    async getAllCompanyJobsAsATalent({commit}, {site_name,email}) {
        return getAllCompanyJobsAsATalent({site_name, email}).then(
            res => {
                console.log(res.data)
                commit('getAllCompanyJobsAsATalentSuccess', res.data)
                return Promise.resolve()
            },
        )
    },
    saveAJob({commit}, {email, job_id}) {
        return saveAJob({email, job_id}).then(
            res => {
                console.log(res.data)
                commit('saveAJobSuccess', res.data)
                return Promise.resolve()
            },
        )
    }
}
