import getters from "@/store/createProfile/getters";
import mutations from "@/store/createProfile/mutations";
import actions from "@/store/createProfile/actions";

const state = {
    profileData: {},
    applicantCV: {},
    resume_info: {},
    user: {
        personal_information: {},
        work_experience: [
            {
                "id": 2,
                "first_job": true,
                "employee_or_company": "semicolon",
                "job_title": "software developer",
                "start_date": "2008-06-27",
                "end_date": "2018-06-27",
                "description": "",
                "currently_work_here": true
            }
        ],
        education: [
            {
                "id": 2,
                "school_or_training": "semicolon",
                "degree_or_certificate": "enginnering",
                "program_or_course": "program",
                "start_date": "2021-06-27",
                "end_date": "2021-06-27",
                "still_enrolled": true
            }
        ],
        portfolio_and_social: [{
            id: 7,
            linkedin_profile: "www.lemon.com",
            others: ["www.lemon.com"]

        }
        ],
        skills: [
            {
                skills: [
                    "jumping",
                    "shooting"
                ]
            }
        ],
        awards_and_certificate: [
            {
                "id": 2,
                "title": "udemy product management",
                "description": "how to manage a team",
                "date_added": "2021-08-27"
            },
            {
                "id": 1,
                "title": "udemy product management",
                "description": "how to manage a team",
                "date_added": "2021-08-27"
            },
        ]
    },
    loading: false
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

