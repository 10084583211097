import {convertSocialToken, createUserIfLoginWithSocial, userFirstLogin} from "@/services/api/AuthApiService";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";
import displaySnackbar from "@/services/utils/snackbar";
import router from "@/router";


export default {
    methods: {
        async googleAuth () {
            sessionStorage.removeItem('userToken')
           const data = await this.$gAuth.signIn()
            console.log("data",data)
            const keys = Object.keys(data)
            let token_object
            for (let i = 0; i < keys.length; i++) {
                if (data[keys[i]].access_token) {
                    token_object = data[keys[i]]
                    break
                }
            }
            console.log(token_object)
            await convertSocialToken(
                {
                    client_id:process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
                    client_secret: process.env.VUE_APP_GOOGLE_OAUTH_SECRET_ID,
                    grant_type: "convert_token",
                    backend: "google-oauth2",
                    token: token_object.access_token

                }
            )
                .then(res=>{
                    console.log(res.data)
                    let decodedToken = VueJwtDecode.decode(token_object.id_token)
                    let social_token = {
                        email:decodedToken.email,
                        access_token:res.data.access_token,

                    }
                    sessionStorage.setItem('userData', JSON.stringify(decodedToken))
                    sessionStorage.setItem('userToken',JSON.stringify(social_token))
                    this.checkIsFirstLogin()
                    this.createUserWithSocialLogin()

                })
                .catch(err=>{
                    console.log(err.response.data)
                    displaySnackbar({
                        message: err.response.data.detail,
                        success: false
                    })
                })
        },
       async checkIsFirstLogin(){
            await userFirstLogin().then(res=>{
                console.log(res.data,"Data to check")
                if (res.data.is_first_login){
                    router.push({name : 'CreateProfile',params:{step:'1'}})
                }
                else {

                    router.push({name:'Dashboard'})
                }
            })
                .catch(err=>{
                    let errorKey = Object.keys(err.response.data)
                    displaySnackbar({
                        message: err.response.data[errorKey[0]],
                        success: false
                    })
                })
        },
        async createUserWithSocialLogin(){
            let user = JSON.parse(sessionStorage.getItem('userData'))|| null
           await createUserIfLoginWithSocial({
               email: user.email,
               first_name: user.given_name,
               last_name:user.family_name,

           })
               .then(res=>{
                   console.log(res)
               })
               .catch(err=>{
                   console.log(err)
            })
        },
        linkedInAuth(){
            axios.get('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77lmgaujr34tt6&scope=r_liteprofile&state=123456&redirect_uri=http://localhost:8080')
                .then(res=>{
                    console.log(res)
                })
                .catch(err=>{
                    console.log(err)
                })
        }
    }
}
