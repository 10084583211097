import {
    candidateAcceptOffer,
    candidateDeclineOffer, checkInvitedCandidate,
    getCandidateOffer,
    inviteCandidateForExternalTest
} from "@/services/api/APIService";

export default {
    async getCandidateOffer({commit}, data) {
        await getCandidateOffer(data).then(res => {
            commit('set_GetOffer', res.data)
        })
    },

    async candidateAcceptOffer({commit}, candidate_email, job_id) {
        await candidateAcceptOffer(candidate_email, job_id).then(res => {
            commit('set_AcceptOffer', res.data)
        })
    },
    async candidateDeclineOffer({commit}, data) {
        await candidateDeclineOffer(data).then(res => {
            commit('set_RejectOffer', res.data)
        })
    },
    async inviteCandidateForExternalTest({commit}, data){
        await inviteCandidateForExternalTest(data).then(res=> {
            commit('set_invite', res.data)
        })
    },
    async checkInvitedCandidate({commit}, data){
        await checkInvitedCandidate(data).then(res=> {
            commit('set_is_invited', res.data)
        })
    }
}