export default {
    SET_COMPANY_SUMMARY: (state, data) =>{
        state.companyProfile = data
    },
    SET_REVIEW: (state, data) =>{
        state.reviews = data
    },
    SET_REVIEW_INSIGHT: (state, data) =>{
        state.ratingInsight = data
    },
    set_new_review:(state,data) =>{
        state.reviews.results = [data,...state.reviews.results]
    },
    SET_LOADING:(state,status)=>state.loading=status,
    getCompanyFaqsSuccess:(state, data) =>{
        state.faqs = data
    },
    getDescriptionAndMediaSuccess:(state, data) =>{
        state.descriptionAndMedia = data
    },
    getCompanyAwardsSuccess:(state, data) =>{
        state.companyAwards = data
    },
    getAllCompanyJobsAsATalentSuccess:(state, data) =>{
        state.jobs = data
    }
}