// import callEndpoint from "@/services/api";
import axios from "axios";
import callEndpoint, {attachQueryParamsToUrl} from "@/services/api/index";
import METHODS from "@/services/api/apiMethods";
// import authHeader from "@/services/api/auth-header";
//
//  const TALENT_URL = "https://enum-talent-api.herokuapp.com/api"
 let RECRUIT_BASE_URL;
 let OPTS_URL;

     if (process.env.NODE_ENV === "development") {
         RECRUIT_BASE_URL = process.env.VUE_APP_DEV_RECRUITER_BASE_URL
         OPTS_URL = process.env.VUE_APP_DEV_OPTS_BASE_URL
     } else {
         RECRUIT_BASE_URL = process.env.VUE_APP_PROD_RECRUITER_BASE_URL
         OPTS_URL = process.env.VUE_APP_PROD_OPTS_BASE_URL
     }

 const {
    GET,
     POST,
    // PUT,
    PATCH,
    DELETE
} = METHODS

 export  const getUserProfile = () => {
     const url = `/api/user/user-profile/get_user_profile/`
     return callEndpoint({url})
 }
export  const adminGetUserProfile = async(email) => {
    const url = `/api/user/user-profile/admin_get_user_profile/?email=${email}`
    return callEndpoint({url})
}
 export const getBadgeDetail = async (badge) => {
     const url = `/operations/vet/badge/?badge_id=${badge}`
     return callEndpoint({url})
 }
export  const jobAlertExist = async data => {
    const url = `/api/user/user-profile/check_if_job_search_keyword_in_talent_job_alert/?email=${data.email}&query=${data.search}`
    return callEndpoint({url})
}
 export  const getUserJobPreference = async data => {
     const url = '/corporate/search/get_job_by_user_job_preference/'
     return callEndpoint({method:POST,url, needsAuth: false, data}, RECRUIT_BASE_URL)
 }
 export const getExploreJobByLocation = async (location, data)  => {
     const url = `/corporate/search/get_job_by_user_location/?location=${location}`
     return callEndpoint({method:POST, url,needsAuth: false, data }, RECRUIT_BASE_URL)
 }
 export const searchJob = async textData => {
     const url = `/corporate/search/search_bar/`
     return callEndpoint({url: attachQueryParamsToUrl(url, textData), needsAuth: false}, RECRUIT_BASE_URL)
 }
 export const sendJobAlert = async data => {
     const url = `/api/user/user-profile/add_job_alert/`
     return callEndpoint({method:PATCH, url, data})
 }
 export const filterJob = async textData => {
     const url = `/corporate/search/filter_job/`
     return callEndpoint({url: attachQueryParamsToUrl(url, {page_size: 12, ...textData}), needsAuth: false}, RECRUIT_BASE_URL)
 }

 export const searchJobsByPagination = async data => {
     const url = `/corporate/job/get_jobs_with_pagination/`
     return callEndpoint({url: attachQueryParamsToUrl(url, data), needsAuth: true}, RECRUIT_BASE_URL)
 }
 // export const getAllJobs = async data => {
 //     const url = `/corporate/job/get_jobs_with_pagination/?page_size=${data.page}`
 //     return callEndpoint({method:GET, url, needsAuth: true}, RECRUIT_BASE_URL)
 // }
 export const getReviews = async id => {
     const url = `/corporate/review/?company_id=${id}&page_size=5`
     return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
 }
 export const getEnumSkills = () => {
     const url = `/operations/question-area/get_all_vetted_question_areas/`
     return callEndpoint({method:GET, url}, OPTS_URL)
 }
 export const getExternalTest = () => {
     const url = `/operations/question-area/get_external_tests/`
     return callEndpoint({method:GET, url}, OPTS_URL)
 }
 export const inviteForExternalTest = (data) => {
     const url = `/operations/question-area/invite_candidates_for_external_test/`
     return callEndpoint({method:PATCH, url,data}, OPTS_URL)
 }
 export const createVettingStatus = (data) => {
     const url = `/operations/status/create_vetting_status/`
     return callEndpoint({method:POST, url,data}, OPTS_URL)
 }
 export const getReviewInsight = async id => {
     const url = `/corporate/review/get_rating?company_id=${id}`
     return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
 }
 export const getReviewsPaginated = async data => {
     const url = `/corporate/review/?company_id=${data.id}&page=${data.page_num}&page_size=5`
     return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
 }
 export const sendReview = async data => {
     const url = `/corporate/review/`
     return callEndpoint({method:POST, url,data}, RECRUIT_BASE_URL)
 }
 export const sendVetQuestions = async data => {
     const url = `/operations/vet/`
     return callEndpoint({method:POST, url,data}, OPTS_URL)
 }

export const uploadToCloudinary = async data => {
    console.log(data, "Data to save")
    return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
const COUNTRY_BASE_URL = 'https://countriesnow.space/api/v0.1/countries/states'
export const getAllCountries = async () => {
    return axios.get(COUNTRY_BASE_URL)
}

export const applicantProfile = async (data) =>{
    const  url = '/api/user/onboarding/add_personal_information/'
    return callEndpoint({method:PATCH,url,data})
}
export const getApplicantProfileById = async () => {
    const url = `/api/user/onboarding/get_personal_information/`
    return callEndpoint({url})
}
export const createResume = data => {
    const url = '/api/user/onboarding/create_resume/'
    return callEndpoint({method: POST, url, data})
};
export const sendWorkExperience = data => {
    const url = '/api/user/work-experience/'
    return callEndpoint({method: POST, url, data})
};
export const getWorkExperience = () => {
    const url = '/api/user/work-experience/'
    return callEndpoint({url})
};
export const sendEducationHistory = data => {
    const url = '/api/user/applicant-education/'
    return callEndpoint({method: POST, url, data})
};
export const getEducationHistory = () => {
    const url = '/api/user/applicant-education/'
    return callEndpoint({url})
};
export const getSkills = () => {
    const url = '/api/user/applicant-skill/'
    return callEndpoint({url})
};
export const sendSkills = data => {
    const url = '/api/user/applicant-skill/'
    return callEndpoint({method: POST, url, data})
};
export const getAwards = () => {
    const url = '/api/user/applicant-awards/'
    return callEndpoint({url})
};
export const sendAwards = data => {
    const url = '/api/user/applicant-awards/'
    return callEndpoint({method: POST, url, data})
};
export const sendPortfolio = data => {
    const url = '/api/user/applicant-portfolio/'
    return callEndpoint({method: POST, url, data})
};
export const getPortfolio = () => {
    const url = '/api/user/applicant-portfolio/'
    return callEndpoint({url})
};
export const getResumePreview = () => {
    const url = '/api/user/onboarding/applicant_onboarding_summary/'
    return callEndpoint({url})
};
export const getJobShortlistingCriteria = async ({job_id}) => {
    const url = `/recruitment/shortlisting-criteria/get_shortlisting_criteria/?job_id=${job_id}`
    return callEndpoint({url})
}
// export const getJobDetails = async (job_id) => {
//     const url = `/corporate/job/job_detail/?job_id=${job_id}`
//     return callEndpoint({url}, RECRUIT_BASE_URL)
// }
export const getCandidateCVs = async () => {
    const url = `/recruitment/applicant-resume/`
    return callEndpoint({url})
}
export const getIndividualCandidateCV = async () => {
    const url = `/recruitment/applicant-resume/`
    return callEndpoint({url})
}
export const applyForJob = async data => {
    const url = `/recruitment/application/`
    return callEndpoint({method: POST, url, data})
}
export const uploadCV = async data => {
    const url = `/recruitment/applicant-resume/`
    return callEndpoint({method: POST, data, url})
}
export const submitApplicantResponse = async data => {
    const url = `/recruitment/shortlisting-result/`
    return callEndpoint({method: POST, data, url})
}
export const getProfile = () => {
    const url = '/api/user/onboarding/get_app_user_info/'
    return callEndpoint({method: GET, url})
};
export const convertSocialToken = data => {
    const url = '/social/convert-token/'

    return callEndpoint({method: POST, url, data})
};
export const followOrUnfollowCompany = async (data) => {
    const url = `/api/user/onboarding/like_or_unlike_company/`
    return callEndpoint({method: POST, url, data})
}
// export const getCompanySummary = async ({site_name, candidate}) => {
//     const url = `/corporate/company/get_company_summary/?site_name=${site_name}&candidate=${candidate}`
//     return callEndpoint({url}, RECRUIT_BASE_URL)
// }
export const guestGetCompanySummary = async ({site_name}) => {
    const url = `/corporate/company/get_company_summary/?site_name=${site_name}`
    return callEndpoint({url}, RECRUIT_BASE_URL)
}
export const isUserAppliedForJob = async ({job_id, candidate_email}) => {
    const url = `/recruitment/application/verify_application/?job_id=${job_id}&email=${candidate_email}`
    return callEndpoint({url})
}
export const getInterviewDetail = async (data) =>{
    const url = `/thirdparty/interview/get_interview_detail/?interview_id=${data.job_id}&email=${data.candidate_email}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const getApplicantStage = async (data) =>{
    const url = `/recruitment/stage/get_stages/?email=${data.candidate_email}&job_id=${data.job_id}`
    return callEndpoint({url})
}
export const declineInterviewOffer = async (data) =>{
    const url = '/recruitment/application/decline_interview_invite/'
    return callEndpoint({method:POST,url,data})
}
export const acceptInterviewOffer = async (data) =>{
    const url = '/recruitment/application/accept_interview_invite/'
    return callEndpoint({method:POST,url,data})
}
export const savedJob = async  ({job_id, candidate_email}) =>{
    const url = `/recruitment/application/save_job/?email=${candidate_email}&job_id=${job_id}`
    return callEndpoint({method:PATCH,url})
}
export const getAllSavedJobs = async  (candidate_email) =>{
    const url = `/recruitment/application/get_saved_jobs/?email=${candidate_email}`
    return callEndpoint({url})
}
export const getAllAppliedJobs = async (candidate_email) =>{
    const url = `/recruitment/application/get_applied_jobs/?email=${candidate_email}`
    return callEndpoint({url})
}
export const getCandidateOffer = async (data) =>{
    const url = `/corporate/make_offer/get_talent_offer/?email=${data.email}&job_id=${data.job_id}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}

export const candidateAcceptOffer = async (data) =>{
    const url = `/corporate/make_offer/accept_offer/?email=${data.email}&job_id=${data.job_id}`
    return callEndpoint({method: PATCH, data, url}, RECRUIT_BASE_URL)
}

export const candidateDeclineOffer = async (data) =>{
    const url = `/corporate/decline_offer/decline_offer/`
    return callEndpoint({method: POST, data, url}, RECRUIT_BASE_URL)
}
export const getAllScreeningTestForJobAssessment= async(job_id)=>{
    const url = `/corporate/screening-test/get_job_question_area/?job_id=${job_id}`
    return callEndpoint({url,job_id}, RECRUIT_BASE_URL)
}

export const getUserProfileDetail = async () =>{
    const url = `/api/user/user-profile/get_user_profile/`
    return callEndpoint({url})
}
 export const updateUserProfile = async  (data) =>{
    const url = `/api/user/user-profile/edit_profile_bio/`
     return callEndpoint({method:PATCH,url,data})
 }

 export const getAllEducations = async () =>{
    const url =`/api/user/applicant-education/`
     return callEndpoint({url})
 }
export const adminGetAllEducations = async (email) =>{
    const url =`/api/user/applicant-education/admin_get_applicant_education/?email=${email}`
    return callEndpoint({url})
}

export const addEducation = async (data) =>{
    const url = `/api/user/applicant-education/add_profile_education/`
     return callEndpoint({method:POST,url,data})
 }

 export const updateEducation = async  (data) => {
    const url = `/api/user/applicant-education/${data.id}/`
     return callEndpoint({method:PATCH,url,data})
 }

 export  const deleteEducation = async  (educationId) =>{
    const url = `/api/user/applicant-education/${educationId}/`
     return callEndpoint({method:DELETE,url,needsAuth: true})
 }

 export  const addLink = async (data) =>{
    const url =`/api/user/applicant-portfolio/add_profile_link/`
     return callEndpoint({method:POST, url,data})
 }

 export const updateLink = async  (data) =>{
    const url = `/api/user/applicant-portfolio/${data.id}/`
     return callEndpoint({method:PATCH,url,data})
 }

 export const getAllLinks = async  () =>{
    const url = `/api/user/applicant-portfolio/`
     return callEndpoint({url})
 }

export const getCompanyFaqs = async site_name => {
    const url = `/corporate/faqs/?site_name=${site_name}`
    return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
}
export const getCompanyDescriptionAndMedia = async site_name => {
    const url = `/corporate/company/get_company_profile/?site_name=${site_name}`
    return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
}
export const getCompanyAwards = async site_name => {
    const url = `/corporate/award/?site_name=${site_name}`
    return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
}
export const createSpecializeProfile = async (data) => {
    const url = `/api/user/special-profile/`
    return callEndpoint({method:POST,url,data})
}

export const updateSpecializeProfile = async (data) =>{
    const url = `/api/user/special-profile/${data.special_profile_id}/`
    return callEndpoint({method:PATCH,url,data})
}

export const getAllSpecialProfiles = async () =>{
    const url = `/api/user/special-profile/`
    return callEndpoint({url})
}
export const adminGetAllSpecialProfiles = async (email) =>{
    const url = `/api/user/special-profile/admin_get_special_profile/?email=${email}`
    return callEndpoint({url})
}


export const getAllCompanyJobsAsATalent = async ({site_name, email}) =>{
    const url = `/thirdparty/jobs/get_all_company_job/?site_name=${site_name}&user_email=${email}`
    return callEndpoint({method:GET, url}, RECRUIT_BASE_URL)
}
export const saveAJob = async ({email, job_id}) =>{
    const url = `/recruitment/application/save_job/?email=${email}&job_id=${job_id}`
    return callEndpoint({method:PATCH,url})
}

export const addWorkExperienceToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-work-experience/?special_profile=${data.special_profile_id}`
    return callEndpoint({method:POST,url,data})
}

export const updateWorkExperienceToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-work-experience/${data.id}/`
    return callEndpoint({method:PATCH,url,data})
}

export const deleteWorkExperienceToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-work-experience/${data.id}/`
    return callEndpoint({method:DELETE,url})
}
export const getAllWorkExperienceInASingleSpecializeProfile = async (data) =>{
    const url = `/api/user/special-profile-work-experience/?special_profile=${data.special_profile_id}`
    return callEndpoint({url})
}

export const addCertificateToSpecialProfile = async  (data) =>{
    const url = `/api/user/special-profile-certification/?special_profile=${data.special_profile_id}`
    return callEndpoint({method:POST,url,data})
}

export const updateCertificateToSpecialProfile = async  (data) =>{
    const url = `/api/user/special-profile-certification/${data.id}/`
    return callEndpoint({method:PATCH,url,data})
}

export  const deleteCertificateToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-certification/${data.id}/`
    return callEndpoint({method:DELETE,url})
}

export const addAccomplishmentToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-accomplishment/?special_profile=${data.special_profile_id}`
    return callEndpoint({method:POST,url,data})
}

export const updateAccomplishmentToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-accomplishment/${data.id}/`
    return callEndpoint({method:PATCH,url,data})
}

export const deleteAccomplishmentToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-accomplishment/${data.id}/`
    return callEndpoint({method:DELETE,url})
}

export const updateSkillsToSpecialProfile = async (data) =>{
    const url = `/api/user/special-profile-skill/${data.id}/`
    return callEndpoint({method:PATCH,url,data})
}

export const getUsersJobAlerts = async  () =>{
    const url = `/api/user/user-profile/get_job_alert/`
    return callEndpoint({url})
}

export const deleteJobAlert = async (data) => {
    const url = `/api/user/user-profile/remove_a_job_alert/`
    return callEndpoint({method:PATCH,url,data})
}

export const getUserInterest = async (email) =>{
    const url = `/thirdparty/company/get_trainee_company_interest/?email=${email}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const getAssessmentStatus= async(email,id)=>{
    const url = `/recruitment/progress/check_category_completed/?job_id=${id}&email=${email}`
    return callEndpoint({url})
}
export const getAssessmentQuestionByCategory=async(id, category)=>{
    const url = `/corporate/screening-test/get_job_screening_questions/?category=${category}&job_id=${id}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const startAssessmentByCategory = async (email,id,data)=>{
    const url =`/recruitment/stage/start_job_test/?email=${email}&job_id=${id}`
    return callEndpoint({method:PATCH,url,data})
}
export const checkIfApplicantHasStartedTest = async (id,email,category)=>{
    const url =`/recruitment/progress/check_test_category_started/?email=${email}&job_id=${id}&category=${category}`
    return callEndpoint({url})
}
export const submitApplicantAssessmentResponse = async(data)=>{
    const url = `/recruitment/test-response/`
    return callEndpoint({method:POST,url,data})
}
export const giveAssessmentFeedback = async(data)=>{
    const url=`/recruitment/feedback/`
    return callEndpoint({method:POST,url,data})
}

export const getDashboardPercentage = async  () =>{
    const url = `/api/user/onboarding/get_applicant_onboarding_state/`
    return callEndpoint({url})
}
export const getVettingStatus = async  () =>{
    const url = `/operations/status/get_vetting_status/`
    return callEndpoint({url},OPTS_URL)
}


export const isJobSaved = async (data) => {
    const url = `/recruitment/application/is_saved/?email=${data.email}&job_id=${data.jobId}`
    return callEndpoint({url})
}
export const temporaryScoreApplicant=async(id)=>{
    const url = `/recruitment/report/temp_score_applicants/?job_id=${id}`
    return callEndpoint({url})
}
export const getAllScreeningTestForProgramAssessment= async(program_id)=>{
    const url = `/corporate/program/get_program_question_area_status?program_id=${program_id}`
    return callEndpoint({url,program_id}, RECRUIT_BASE_URL)
}
export const getProgramAssessmentStatus= async(id)=>{
    const url = `/corporate/program/check_category_completed/?program_id=${id}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const checkIfApplicantHasStartedProgramTest = async (id,category)=>{
    const url =`/corporate/program/check_test_category_started/?program_id=${id}&category=${category}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const getEnumVettedQuestions = async (id, link_token)=>{
    const url =`/operations/question-area/get_questions_for_vetted_question_area/?question_area_id=${id}&link_token=${link_token}`
    return callEndpoint({url},OPTS_URL)
}
export const startProgramAssessmentByCategory = async (data)=>{
    const url =`/corporate/program/start_program_test/`
    return callEndpoint({method:PATCH,url,data},RECRUIT_BASE_URL)
}
export const getProgramAssessmentQuestionByCategory=async(id, category)=>{
    const url = `/corporate/program/get_program_screening_questions/?category=${category}&program_id=${id}`
    return callEndpoint({url},RECRUIT_BASE_URL)
}
export const submitApplicantProgramAssessmentResponse = async(data)=>{
    const url = `/corporate/program/submit_assessment_response/`
    return callEndpoint({method:POST,url,data},RECRUIT_BASE_URL)
}
export const updateUserAboutMe = async (data) => {
    const url = `/api/user/user-profile/about/`
    return callEndpoint({method:PATCH,url,data})
}
export const getAllListOfBadges = async () => {
    const url = `/operations/vet/badges/`
    return callEndpoint({url})
}
export const inviteCandidateForExternalTest = async (data) =>{
    const url = '/recruitment/application/invite_candidate_for_external_test/'
    return callEndpoint({method:PATCH,url,data})
}
export const checkInvitedCandidate = async (data) =>{
    const url = '/recruitment/application/check_candidate_invited_for_external_test/'
    return callEndpoint({method:PATCH,url,data})
}