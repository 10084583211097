import getters from "@/store/jobStatus/getters";
import mutations from "@/store/jobStatus/mutations";
import actions from "@/store/jobStatus/actions";

const state = {
    get_Offer: {},
    acceptOffer: {},
    rejectOffer: {},
    externalTestInvite: {},
    is_invited: false
}

export default {
    state,
    getters,
    mutations,
    actions
}