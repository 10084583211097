import {
    createResume, getAwards, getEducationHistory, getIndividualCandidateCV, getPortfolio, getResumePreview, getSkills,
    getWorkExperience, sendAwards,
    sendEducationHistory, sendPortfolio,
    sendSkills,
    sendWorkExperience
} from '../../services/api/APIService';
import displaySnackbar from "@/services/utils/snackbar";


import {applicantProfile,getApplicantProfileById} from "@/services/api/APIService";

export default {
   async applicantProfile({commit},data){
       await applicantProfile(data).then(res =>{
           commit('SET_APPLICANT_DATA',res.data)
           sessionStorage.setItem('createProfileId',res.data.id)
           return Promise.resolve(res)
        })
            .catch(err =>{
                return Promise.reject(err)
            })
    },

    async getApplicantData({commit}){
        await getApplicantProfileById().then(res =>{
            commit('SET_APPLICANT_DATA',res.data)
        })
            .catch(err =>{
                displaySnackbar({message: err.response.data.message,
                    success: false})
                console.log(err)
            })
    },
    async getApplicantCV({commit}){
        await getIndividualCandidateCV().then(res =>{
            commit('SET_APPLICANT_CV',res.data)
            return Promise.resolve(res);
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message,
                    success: false})
                return Promise.reject(err);

            })
    },
    createResume({commit}, payload) {
        return createResume(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createWorkExperience({commit}, payload) {
        return sendWorkExperience(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getWorkExperience({commit}) {
        commit('SET_LOADING',true)
        return getWorkExperience().then(
            response => {
                commit("SET_RESUME", response);
                commit('SET_LOADING',false)
                return Promise.resolve(response.data);
            },
            error => {
                commit('SET_LOADING',false)
                return Promise.reject(error);
            }
        );
    },
    createEducationHistory({commit}, payload) {
        return sendEducationHistory(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getEducationHistory({commit}) {
        return getEducationHistory().then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createSkills({commit}, payload) {
        return sendSkills(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getSkills({commit}) {
        return getSkills().then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createAwards({commit}, payload) {
        return sendAwards(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            });
    },

    getAwards({commit}) {
        return getAwards().then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createPortfolio({commit}, payload) {
        return sendPortfolio(payload).then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            });
    },
    getPortfolio({commit}) {
        return getPortfolio().then(
            response => {
                commit("SET_RESUME", response);
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            });
    },
    getProfileSummary({commit}) {
        return getResumePreview().then(
            response => {
                commit("SET_PROFILE", response);
                // sessionStorage.setItem('userResumeSummary', JSON.parse(response.data))
                return Promise.resolve(response.data);
            },
            error => {
                return Promise.reject(error);
            });
    },
}



