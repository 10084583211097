export default {
    set_GetOffer: (state, data) => {
        state.get_Offer = data
    },

    set_AcceptOffer: (state, data) => {
        state.acceptOffer = data
    },

    set_RejectOffer: (state, data) => {
        state.rejectOffer = data
    },

    set_invite: (state, data) => {
        state.externalTestInvite = data
    },
    set_is_invited: (state, data) => {
        state.is_invited = data
    }
}