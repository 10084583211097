import getters from "@/store/onboarding/getters";
import mutations from "@/store/onboarding/mutations";
import actions from "@/store/onboarding/actions";
const state={
    isUserLogIn: false,
    user:{},
    percentage_complete:null,
    loading:false
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}
