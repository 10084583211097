import {userFirstLogin} from "@/services/api/AuthApiService";
import VueJwtDecode from "vue-jwt-decode";
import router from "@/router";
import displaySnackbar from "@/services/utils/snackbar";
export default {
        async login({commit,dispatch},token){
            commit('SET_LOADING',true)
            let decodedToken = VueJwtDecode.decode(token)
            console.log(decodedToken)
            sessionStorage.setItem('userData', JSON.stringify(decodedToken))
            sessionStorage.setItem('userToken', token)
            commit('SET_USER_LOG_IN',true)
            await userFirstLogin().then(res=>{
                dispatch('applicantProfile/getApplicant',null, { root: true })
                console.log(res)
                if (!res.data.on_boarding_progress.job_preference){
                    router.push({name : 'CreateProfile',params:{step:'1'}})
                }
                else if(router.currentRoute.query.redirect){
                    console.log(router.currentRoute.query)
                    dispatch('redirectToUserPreviousPage')
                }
                else {
                    router.push({name:'Dashboard'})
                    console.log(router.currentRoute.query)

                }
            })
                .catch(err=>{
                    let errorKey = Object.keys(err.response.data)
                    displaySnackbar({
                        message: err.response.data[errorKey[0]],
                        success: false
                    })
                }).finally(() => {
                    commit('SET_LOADING', false)
                })

            // await login(data).then()
            //     .catch(err=>{
            //         console.log(err)
            //         let errorKey = Object.keys(err.response.data)
            //         displaySnackbar({
            //             message: err.response.data[errorKey[0]],
            //             success: false
            //         })
            //     })
            //     .finally(()=>commit('SET_LOADING',false))

        },
    redirectToUserPreviousPage(){
        let updateQuery = {...router.currentRoute.query}
        delete updateQuery.token
            router.push({
                path: router.currentRoute.query.redirect,
                query: updateQuery
                // name: router.currentRoute.query.routeName,
                // query: {jobId: router.currentRoute.query.jobId}
            }).then(r =>{return r})
    }
}
