import getters from "@/store/createSpecialiseProfile/getters";
import mutations from "@/store/createSpecialiseProfile/mutations";
import actions from "@/store/createSpecialiseProfile/actions";


const state = {
    createSpecializeData :{}
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}