import Vue from 'vue'
import VueRouter from 'vue-router'
import SignUp from '@/views/onboarding/SignUpView'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'SignUpView',
        component: SignUp,
        meta: {
            notProtected: true,
        },
        redirect:'/search'

    },

    {
        path: '/profile/badge',
        name: 'enumBadge',
        component: () => import('@/views/onboarding/enumBadge'),
        meta: {
            notProtected: true,
        }
    },

    {
        path: '/login',
        name: 'LoginView',
        component: () => import('@/views/onboarding/LoginView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: () => import('@/views/onboarding/ResetView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/reset-password',
        name: 'ChangePassword',
        component: () => import('@/views/onboarding/ChangePasswordView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/create-profile/:step',
        name: 'CreateProfile',
        component: () => import('../views/createProfile/CreateProfile'),
    },
    {
        path: '/create-specialized/:step',
        name: 'CreateSpecializeProfile',
        component: () => import('@/views/CreateSpecializeProfile')
    },
    {
        path: '/search/details',
        name: 'JobDetailsAsQuestView',
        component: () => import('@/components/dashboard/reuseables/JobDetailsCardAsGuest'),
        meta: {
            notProtected: true,
        }
    },

    {
        path: '/success',
        name: 'SuccessNotification',
        component: () => import('@/components/onboarding/SignUpSuccess'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/verify-email',
        name: 'VerifyAccount',
        component: () => import('@/components/onboarding/VerifyEmail'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/view-company/:tab',
        name: 'ViewCompany',
        component: () => import('@/components/viewCompanyProfile/components/ViewCompany'),
    },
    {
        path: '/vetting',
        name: 'Vetting',
        component: () => import('@/components/dashboard/components/Vetting/Vetting'),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/ApplicantDashboard'),
        redirect: {name: 'ApplicantDashboard'},
        children: [
            {
                path: "",
                name: 'ApplicantDashboard',
                component: () => import('@/components/dashboard/components/ApplicantDashboardHome')

            },
            {
                path: 'jobs',
                name: 'Jobs',
                component: () => import('@/components/dashboard/components/Jobs'),
                redirect: {name: 'AppliedJobs'},
                meta: {
                    hideCurrentHeader: true
                },
                children: [
                    {
                        path: "applied",
                        name: "AppliedJobs",
                        component: () => import('@/components/dashboard/jobs/AppliedJobs')
                    },
                    {
                        path: "saved",
                        name: "SavedJobs",
                        component: () => import('@/components/dashboard/jobs/SavedJobs')
                    },
                ]
            },
            {
                path: 'practice',
                name: 'Practice',
                component: () => import('@/components/dashboard/components/Practice')
            },
            {
                path: 'jobPreferences',
                name: 'JobPreferences',
                component: () => import('@/components/dashboard/components/JobPreferences')
            },
        ]
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/components/dashboard/components/Profile'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/user-profile',
        name: 'UserProfileRedirect',
        component: () => import('@/components/dashboard/UserProfileRedirect'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: "/search",
        name: "SearchResult",
        component: () => import('@/components/dashboard/jobs/SearchResult'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: "/dashboard/jobs/applied/:job_id",
        name: "AppliedJobDetails",
        component: () => import('@/components/dashboard/reuseables/AppliedJobDetails')
    },

    {
        path: "/dashboard/jobs/applied/:job_id/offerState",
        name: "OfferDetailsState",
        component: () => import("@/components/dashboard/jobs/candidateOfferState/OfferDetailsState")
    },
    {
        path: "/test-type",
        name: "AssessmentTypeView",
        component: () => import("@/views/jobAssessment/AssessmentTestTypeView"),

    },
    {
        path: "/test-instruction",
        name: "AssessmentInstructionView",
        component: () => import("@/components/dashboard/jobAssessment/AssessmentInstruction"),
        meta: {
            notProtected: true,
        }
    },
    {
        path: "/test",
        name: "AssessmentQuestionView",
        component: () => import("@/views/jobAssessment/AssessmentQuestionView"),
        meta: {
            notProtected: true,
        }
    },
    {
        path: "/test/personality",
        name: "AssessmentQuestionPersonalityType",
        component: () => import("@/components/dashboard/jobAssessment/PersonalityTestTypeWrapper")
    },
    {
        path: "/test/feedback",
        name: "AssessmentFeedbackView",
        component: () => import("@/components/dashboard/jobAssessment/AssessmentFeedback")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
            behavior: "smooth"
        };
    },
})
router.beforeEach((to, from, next) => {
    const isUserLogIn = !!sessionStorage.getItem('userToken')
    const routeNotProtected = to.matched.some(record => record.meta.notProtected);
    // if (!isUserLogIn && !routeNotProtected) next('/login')
    if (!isUserLogIn && !routeNotProtected) next(
        window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=individual`

            // `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=individual`
    )
    else next()
})

export default router
