<template>
  <v-app-bar color="white" fixed elevation="1" height="76">
    <div class="applyFlexBetween tw-w-full " >
      <div class="tw-hidden lg:tw-block">
        <EnumWithWordIcon @click="goToDashboard"/>
      </div>
      <div class="lg:tw-hidden">
        <slot name="route-back-component">
          <EnumWithoutWordIcon/>
        </slot>
      </div>
      <div>
        <slot name="right-header-component">
        </slot>
      </div>
    </div>
  </v-app-bar>
</template>

<script>

import EnumWithWordIcon from "@/components/icons/EnumWithWordIcon";
import EnumWithoutWordIcon from "@/components/icons/EnumWithoutWordIcon";



export default {
  name: "OnboardingHeader",
  components: {EnumWithoutWordIcon, EnumWithWordIcon},
  data(){
    return{
      loading: false,
      switchRoute : true,
      search: "",
      isMobile: false
    }
  },


  methods: {

    onResize () {
      this.isMobile = window.innerWidth < 600
    },
    async goToDashboard () {
      await this.$router.push({name: 'Dashboard'})
    }
  }
}
</script>

<style scoped>

.v-application .elevation-1 {
  box-shadow: 0px 2px 16px #F0F9FF !important;
}
.applicantHeaderSearch{
  width: 30%;
}
</style>
