import aws from 'aws-sdk'
import axios from "axios";

aws.config.update({
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
})

export const s3 = new aws.S3({
    signatureVersion: 'v4',
    region: process.env.VUE_APP_AWS_REGION,
})

export const uploadToBucket = async (file, folder) => {
    if (!folder) throw new Error('folder cannot be empty.')
    const key = `${folder}/${Date.now()}-${file.name.replace(/\s/g, '-')}`
    const EXPIRATION = 10000
    const ContentType = file.type
    const params = {
        Key: key,
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Expires: EXPIRATION,
        ContentType,
    }

    console.log({params})

    const signedUrl = s3.getSignedUrl('putObject', params)
    try {
        const result = await axios.put(signedUrl, file, { headers: { 'Content-Type': ContentType }})
        console.log(({ result }))
        const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0]
        result.key = key
        result.fullPath = bucketUrl + key
        return result
    } catch (e) {
        console.log({ e })
        throw e
    }
}

export const viewFile = ({url, context, type = 'image', property = 'image'}) => {
    s3.getObject({ Bucket: process.env.VUE_APP_AWS_BUCKET, Key: url }, (err, file) => {
        if (err) {
            console.log(err)
            return
        }
        if (type === 'image') {
            context[property] = `data:image/jpeg;base64,${encode(file.Body)}`
        }
    })
}

export function viewLesson({url}){
    console.log(url)
    return new Promise(function(resolve,reject){
        validSignedURL(url)
            .then(signedURL => {
                resolve(signedURL)
            })
            .catch(e=>{
                reject(e)
            })
    })
}
function validSignedURL(url) {
    console.log("Generating Presigned Link ... ");
    let params = {
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Key: url,
    };

    return new Promise(function(resolve){
        s3.headObject(params).promise()
            .then(function () {
                // console.log('s3 File exists' + data);
                resolve(getSignedURL(url));
            }).catch(function () {
            // console.log('Generating Presigned Link ... Failed' + err);
            resolve('');
        }).finally(()=>{
            // console.log('finish')
        })
        ;
    });
}
function getSignedURL(url) {
    let params = {
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Key: url,
        Expires: 18000,
    };

    return s3.getSignedUrl('getObject', params);
}
function encode(data) {
    const str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}