import {
    filterJob,
    getReviews,
    getUserJobPreference,
    searchJob,
    searchJobsByPagination
} from "@/services/api/APIService";
import {
    getAllJobs,
} from "@/services/api/AuthApiService";
import router from "@/router";


export default {
    async searchJobs({commit}, {payload, $route}){
        commit('SET_LOADING',true)
        await searchJob(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
            if ($route.name !== 'SearchResult') {
                console.log(payload.query)
                router.push({name: 'SearchResult', query:{search:payload.query}})
            }
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            .finally(()=>commit('SET_LOADING',false))
    },
    async filterJobs({commit}, payload){
        commit('SET_LOADING',true)

        await filterJob(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
            if (this.$route.name !== 'SearchResult') {
                router.push({name: 'SearchResult'})
            }
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            .finally(()=>commit('SET_LOADING',false))
    },
    async getAllJob({commit}, payload){
        commit('SET_LOADING',true)
        await getAllJobs(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            .finally(()=>commit('SET_LOADING',false))
    },
    async getJobPreference({commit}, payload){
        commit('SET_LOADING',true)
        await getUserJobPreference(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
        })
            .catch(err =>{
                console.log(err)
                throw err
                // this.$displaySnackbar({message: err?.response?.data?.message, success: false})
            })
            .finally(()=>commit('SET_LOADING',false))
    },
    async fetchPaginatedJobs({commit}, payload){
        commit('SET_LOADING',true)
        await searchJobsByPagination(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            .finally(()=>commit('SET_LOADING',false))
    },
    async fetchReview({commit}, payload){
        commit('SET_LOADING',true)
        await getReviews(payload).then(res=>{
            res.data.results.forEach(d => d.loading = false)
            sessionStorage.setItem("searchRes", JSON.stringify(res.data))
            commit('SET_SEARCH_RESULT', res.data)
        })
            .catch(err =>{
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
            .finally(()=>commit('SET_LOADING',false))
    }
}
