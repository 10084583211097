import {adminGetUserProfile, getEnumSkills, getExternalTest, getUserProfile} from "@/services/api/APIService";
import {updateUserProfile} from "../../services/api/APIService";
// import {viewFile} from "@/services/utils/aws";


export default {
    async getApplicant({commit}) {
        await getUserProfile().then(res => {
            sessionStorage.setItem("userProfile", JSON.stringify(res.data))
            console.log(res.data)
            // viewFile({
            //     url: (JSON.parse(res.data.image)).key,
            //     context: res.data
            // })
            commit('SET_GET_APPLICANT_PROFILE_DATA', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    async adminGetApplicant({commit}, email) {
        await adminGetUserProfile(email).then(res => {
            sessionStorage.setItem("userProfile", JSON.stringify(res.data))
            console.log(res.data)
            // viewFile({
            //     url: (JSON.parse(res.data.image)).key,
            //     context: res.data
            // })
            commit('SET_GET_APPLICANT_PROFILE_DATA', res.data)
        })
    },
    async getEnumSkills({commit,dispatch}) {
        this.loading = true
        try {
            const {data} = await getEnumSkills()
            dispatch('getExternalSkills')
            commit('set_skills', data)

        } catch (e) {
            this.$displaySnackbar({message: e.response.data.message, success: false})
        }
    },
    async getExternalSkills({commit}) {
        try {
            const {data} = await getExternalTest()
            commit('set_skills', data)
        } catch (e) {
            this.$displaySnackbar({message: e.response.data.message, success: false})
        }
    },

    async updateUserProfile({commit}, data) {
        await updateUserProfile(data).then(res => {
            commit('SET_GET_APPLICANT_PROFILE_DATA', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    }
}