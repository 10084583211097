import Vue from 'vue'
import Vuex from 'vuex'
import snackBar from '@/store/snackBar'
import onboarding from '@/store/onboarding'
import companyProfile from '@/store/companyProfile'
import createProfile from '@/store/createProfile'
import applicantProfile from '@/store/applicantProfile'
import personalProfile from '@/store/personalProfile'
import jobSearch from '@/store/jobSearch'
import jobStatus from '@/store/jobStatus'
import createSpecialiseProfile from '@/store/createSpecialiseProfile'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    snackBar,
    onboarding,
    createProfile,
    applicantProfile,
    jobSearch,
    jobStatus,
    personalProfile,
    companyProfile,
    createSpecialiseProfile
  }

})
