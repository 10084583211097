import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    getApplicantProfile: {},
    skills: []
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

