import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import "./assets/css/tailwind.css"
import "./assets/css/global.scss"
import VueFeather from 'vue-feather';
import { ValidationObserver, ValidationProvider, extend, localize} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import displaySnackbar from "../src/services/utils/snackbar";
// import GAuth from 'vue-google-oauth2'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// const gauthOption = {
//   clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
//   scope: 'profile email',
//   prompt: 'select_account'
// }
// Vue.use(GAuth, gauthOption)


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);


Vue.component('vue-feather', VueFeather);

Vue.prototype.$displaySnackbar = displaySnackbar

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  VuePhoneNumberInput,
  store,
  render: h => h(App)
}).$mount('#app')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
