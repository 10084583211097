import axios from "axios";
// import authHeader from "@/services/api/auth-header";
import METHODS from "@/services/api/apiMethods";
import callEndpoint from "@/services/api/index";

const {
    // GET,
    POST,
    // PUT,
    // PATCH,
    // DELETE
} = METHODS

let AUTH_URL;
let RECRUIT_BASE_URL;


if (process.env.NODE_ENV === "development") {
    AUTH_URL = process.env.VUE_APP_DEV_AUTH_URL;
    RECRUIT_BASE_URL = process.env.VUE_APP_DEV_RECRUITER_BASE_URL

} else {
    AUTH_URL = process.env.VUE_APP_PROD_AUTH_URL;
    RECRUIT_BASE_URL = process.env.VUE_APP_PROD_RECRUITER_BASE_URL

}

const authServiceCallEndpoint = ({method, url, data, needsAuth}) => {
    return callEndpoint({method, url, data, needsAuth}, AUTH_URL)
}

export const signUp = data => {
    const url = '/auth/accounts/register/'
    return authServiceCallEndpoint({method: POST, url, data})
}
export const verifyEmail = data => {
    const url = '/auth/accounts/verify-registration/'
    return authServiceCallEndpoint({method: POST, url, data})
}

export const login = async user => {
    const url = '/auth/token/jwt'
    return authServiceCallEndpoint({method: POST, data: user, url})
}
export const logout = () => {
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("userData");
    window.location.href = `${process.env.VUE_APP_AUTH_FRONT_URL}/auth/login?site=individual`;
};

export const resetPassword = data => {
    const url = '/auth/accounts/send-reset-password-link/'
    return authServiceCallEndpoint({method:POST, url, data})
}
export const changePassword = data => {
    const url = '/auth/accounts/reset-password/'
    return authServiceCallEndpoint({method:POST, url, data})
};
export const convertSocialToken = async user => {
    return axios.post(process.env.VUE_APP_PROD_TALENT_BASE_URL+'/social/convert-token/',user)
    // return authServiceCallEndpoint({method: POST, data: user, url})
}


export  const userFirstLogin = async () =>{
    const url = '/api/user/onboarding/get_app_user_info/'
    return callEndpoint({url})
}
export const createUserIfLoginWithSocial = data=>{
    const url = `/auth/user/create_social_media_user/`
    return axios.post(process.env.VUE_APP_PROD_AUTH_URL+url,data)
}
// these endpoints does not need token that's why they are here
export const getJobDetails = async (job_id) => {
    const url = `/corporate/job/job_detail/?job_id=${job_id}`
    return axios.get(RECRUIT_BASE_URL +url)
    // callEndpoint({url}, RECRUIT_BASE_URL)
}
export const getCompanySummary = async ({site_name, candidate}) => {
    const url = `/corporate/company/get_company_summary/?site_name=${site_name}&candidate=${candidate}`
    return axios.get(RECRUIT_BASE_URL +url)
}
export const getAllJobs = async data => {
    const url = `/corporate/job/get_jobs_with_pagination/?page_size=${data.page}`
    return axios.get(RECRUIT_BASE_URL +url)

}
export const getUserInterest = async (email) =>{
    const url = `/thirdparty/company/get_trainee_company_interest/?email=${email}`
    return axios.get(RECRUIT_BASE_URL +url)
}



