import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const state={
    educations:[],
    educationLoading : false,
    links:[],
    profileData:{},
    specialProfiles:[],
    jobAlerts:[]
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}